import View from '../view';

class SearchView extends View {
  _parentEl = document.getElementById('search--state--city--form');

  init(id) {
    this._parentEl = document.getElementById(id);
  }

  _checkElement = async selector => {
    while (document.getElementById(selector) === null) {
      await new Promise(resolve => requestAnimationFrame(resolve));
    }
    return document.getElementById(selector);
  };

  addhandlerSearchSubmit(handler) {
    // if (!this._parentEl)
    //   this._checkElement('search--state--city--form').then(selector => {
    //     console.log(selector);
    //     selector.addEventListener('submit', function (e) {
    //       e.preventDefault();
    //       handler();
    //     });
    //   });
    // else
    this._parentEl.addEventListener('submit', function (e) {
      e.preventDefault();
      handler();
    });
  }
}

export default new SearchView();
