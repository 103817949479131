import View from '../view';

class DropDownView extends View {
  _parentEl = '';
  _type = '';

  initialize(type, data, handler = '') {
    this._type = type;
    this._parentEl = document.getElementById(`search--select--${type}`);
    this._addHandlerFilterEvent();
    this._addHandlerDropdownMenu(handler);
    this.render(data);
  }

  _addHandlerDropdownMenu(handler) {
    this._parentEl.addEventListener('show.bs.dropdown', event => {
      const selectEl = event.currentTarget.id;

      if (selectEl.toLowerCase().indexOf('state') > -1) {
        const listEl = document
          .getElementById(selectEl)
          .querySelectorAll('.search--dropdwon--list li');
        if (listEl.length === 0) handler();
      }
    });
  }

  _addHandlerFilterEvent() {
    this._parentEl.addEventListener('keyup', function (e) {
      const input = e.target;

      if (!input.classList.contains('filter--search')) return;

      const filter = input.value.toUpperCase();
      const listAll = e.currentTarget.querySelectorAll(
        '.search--dropdwon--list li>a'
      );

      listAll.forEach(element => {
        const value = element.dataset.item;
        if (value.toUpperCase().indexOf(filter) > -1) {
          element.classList.remove('d-none');
        } else {
          element.classList.add('d-none');
        }
      });
    });
  }

  update(data) {
    this._parentEl.querySelector('input.filter--search').value = '';
    this._parentEl.querySelector('button.form-select').textContent = data;
  }

  _inputSelect() {
    return `
        <button
            aria-label="select ${this._type}"
            class="btn form-select border text-start"
            type="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
        >
            ${this._data}
        </button>
        <ul class="dropdown-menu shadow-sm w-100">
            <li class="px-3">
                <div class="input-group mb-1">
                    <span
                        class="input-group-text bg-white pe-1"
                    ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-search"
                        viewBox="0 0 16 16"
                    >
                        <path
                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"
                        /></svg
                    ></span>
                    <input
                        class="form-control filter--search border-start-0 ps-1 text-lowercase"
                        type="text"
                        placeholder="search ${this._type}"
                        aria-label="Search ${this._type}"
                    />
                </div>
            </li>
            <ul class="list-unstyled search--dropdwon--list"></ul>
        </ul>
        <label for="search--select--${this._type}" class="form-label text-lowercase">${this._type}</label>
    `;
  }

  _generateMarkup() {
    return this._inputSelect();
  }
}

export default DropDownView;
