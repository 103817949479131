import View from '../view';

class DropDownListView extends View {
  _parentEl = '';

  initialize(type, data) {
    this._type = type;
    this._parentEl = document
      .getElementById(`search--select--${type}`)
      .querySelector('.search--dropdwon--list');
    this.render(data);
  }

  addhandlerSelect(handler) {
    this._parentEl.addEventListener('click', event => {
      event.preventDefault();
      const anchorEl = event.target;

      if (!anchorEl.classList.contains('dropdown-item')) return;
      const value = anchorEl.dataset.item;
      handler(value);
    });
  }

  update(data) {
    this.render(data);
  }

  _generateMarkup() {
    return this._generateDropdownItems();
  }

  _generateDropdownItems() {
    const { data, selected } = this._data;
    return data
      .map(list => {
        const item =
          this._type.toLowerCase().indexOf('state') > -1 ? list : list.name;
        return `<li><a class="dropdown-item${
          item === selected ? ' active' : ''
        }" data-item="${item}" href="#">${item}</a></li>`;
      })
      .join('');
  }
}

export default DropDownListView;
