class View {
  _data;

  addHandlerRender(handler) {
    ['hashchange', 'load'].forEach(ev => window.addEventListener(ev, handler));
  }

  render(data, render = true) {
    if (!data || (Array.isArray(data) && data.length === 0))
      return this.renderError();

    this._data = data;
    const markup = this._generateMarkup();

    if (!render) return markup;

    this._clear();
    this._parentEl.insertAdjacentHTML('afterbegin', markup);
  }

  update(data) {
    if (!data || (Array.isArray(data) && data.length === 0))
      return this.renderError();

    this._data = data;
    const newMarkup = this._generateMarkup();

    const newDom = document.createRange().createContextualFragment(newMarkup);
    const newElements = Array.from(newDom.querySelectorAll('*'));
    const currElements = Array.from(this._parentEl.querySelectorAll('*'));

    newElements.forEach((newEl, i) => {
      const currEl = currElements[i];
      // updates text
      if (
        !newEl.isEqualNode(currEl) &&
        newEl?.firstChild?.nodeValue?.trim() !== ''
      ) {
        currEl.textContent = newEl.textContent;
      }
      // updates attributes
      if (!newEl.isEqualNode(currEl)) {
        // console.log(newEl);
        Array.from(newEl.attributes).forEach(attr =>
          currEl.setAttribute(attr.name, attr.value)
        );
      }
    });
  }

  _clear() {
    this._parentEl.innerHTML = '';
  }

  _removeToast() {
    const toastEl = this._parentEl.querySelector('.toast');
    if (toastEl) this._parentEl.querySelector('.toast').remove();
  }

  renderError(
    message = this._errorMessage,
    bgType = 'text-bg-warning',
    plcaement = 'afterbegin'
  ) {
    const markup = `
    <div class="toast align-items-center ${bgType} border-0 show my-3" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="2000">
      <div class="d-flex">
        <div class="toast-body">
          ${message}
        </div>
        <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
    </div>
    `;

    this._removeToast();
    this._parentEl.insertAdjacentHTML(`${plcaement}`, markup);
  }

  renderMessage(
    message = this._message,
    bgType = 'text-bg-success',
    plcaement = 'afterbegin'
  ) {
    const markup = `
    <div class="toast align-items-center ${bgType} border-0 show my-3" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="2000">
      <div class="d-flex">
        <div class="toast-body">
          ${message}
        </div>
        <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
    </div>
    `;

    this._removeToast();
    this._parentEl.insertAdjacentHTML(`${plcaement}`, markup);
  }

  renderSpinner() {
    const markup = `
      <div class="spinner text-center">
        <div class="spinner-border" style="width: 2rem; height: 2rem;" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow" style="width: 2rem; height: 2rem;" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    `;

    this._clear();
    this._parentEl.insertAdjacentHTML('afterbegin', markup);
  }

  _checkElement = async selector => {
    while (this._parentEl.querySelector(selector) === null) {
      await new Promise(resolve => requestAnimationFrame(resolve));
    }
    return this._parentEl.querySelector(selector);
  };

  _checkElementAll = async selector => {
    while (this._parentEl.querySelectorAll(selector) === null) {
      await new Promise(resolve => requestAnimationFrame(resolve));
    }
    return this._parentEl.querySelectorAll(selector);
  };
}

export default View;
