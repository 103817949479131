import parkingSign from '../../img/header/parking-sign.svg';
import rupeeSvg from '../../img/general/currency-rupee.svg';
import carSvg from '../../img/general/car-front.svg';
import busSvg from '../../img/general/bus-front.svg';
import miniTruckSvg from '../../img/general/truck.svg';

class LeafletView {
  _mapId;
  _map;
  _mapZoomLevel = 12;
  _mapEvent;
  _markers;
  _default = {
    latitude: 12.97194,
    longitude: 77.59369,
  };
  _cityCoords;
  _data;
  _addMarker = false;

  constructor(id, coords = '') {
    this._mapId = id;
    if (coords)
      this._default = {
        latitude: coords[0],
        longitude: coords[1],
      };
    // methods
    this._getPosition();
  }

  _getPosition() {
    if (navigator.geolocation)
      navigator.geolocation.getCurrentPosition(
        this._loadMap.bind(this),
        this._loadMap.bind(this)
      );
  }

  _loadMap(position) {
    const { latitude, longitude } = position.coords
      ? position.coords
      : this._default;
    const coords = [latitude, longitude];

    this._map = L.map(this._mapId).setView(coords, this._mapZoomLevel);
    // https://tile.openstreetmap.fr/hot/{z}/{x}/{y}.png
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this._map);

    if (this._mapId !== 'map') {
      if (this._addMarker)
        this._map.whenReady(
          function () {
            this._renderMarkerDefault();
          }.bind(this)
        );
      this._map.on('click', this._marker.bind(this));
    }
  }

  _marker(mapE) {
    this._mapEvent = mapE;
    this.removeAllMarker();
    this._renderMarker();
  }

  removeAllMarker() {
    if (this._markers) this._map.removeLayer(this._markers);
  }

  _customMarker() {
    return L.divIcon({
      className: 'bi bi-geo-alt-fill',
      html: `<svg width="34" height="34" fill="#fc2a05" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
      </svg>`,
      iconSize: [34, 61],
    });
  }

  _commonMarker(coords) {
    this._markers = L.marker(coords, { icon: this._customMarker() })
      .addTo(this._map)
      .bindPopup(
        L.popup({
          maxWidth: 250,
          minWidth: 100,
          autoClose: false,
          closeOnClick: false,
          className: `location-popup`,
        })
      )
      .setPopupContent(
        `<img src="${parkingSign}" width="34" height="34" alt="parking logo" /> this is the spot!`
      )
      .openPopup();
  }

  _renderMarker() {
    const { lat, lng } = this._mapEvent.latlng;
    const coords = [lat, lng];
    this._commonMarker(coords);
  }

  _renderMarkerDefault() {
    const { lat, lng } = {
      lat: this._default.latitude,
      lng: this._default.longitude,
    };
    const coords = [lat, lng];
    this._commonMarker(coords);
  }

  getMarkers() {
    return this._markers?._latlng;
  }

  setViewTo(coords) {
    this._cityCoords = coords;

    this._map.setView(this._cityCoords, this._mapZoomLevel, {
      animate: true,
      pan: {
        duration: 1,
      },
    });
  }

  geoJson() {
    const onEachFeature = function (feature, layer) {
      let popupContent = `<img src="${parkingSign}" width="34" height="34" alt="parking logo" />`;

      if (feature.properties && feature.properties.popupContent) {
        popupContent += `<div class="d-inline-block me-2">
            <img src="${rupeeSvg}" width="20" height="20" alt="rupee logo" class="mb-1" />
            ${feature.properties.popupContent}
          </div>
          <div class="d-inline-block">
            <img src="${
              feature.post_id.vehicle_type === 'car'
                ? carSvg
                : feature.post_id.vehicle_type === 'bus'
                ? busSvg
                : miniTruckSvg
            }" width="20" height="20" alt="car logo" class="mb-1" />
            ${feature.post_id.parking_space}
          </div>
          <div class="click--view my-1">
            <a
                class="icon-link icon-link-hover ms-5 view--post--map"
                style="
                    --bs-icon-link-transform: translate3d(0, -0.125rem, 0);
                "
                href="#search?id=${feature.post_id._id}"
            >
              <svg class="bi fs-6" aria-hidden="true">
                <path
                d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z"
                />
                <path
                d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z"
                />
              </svg>
            View</a>
          </div>`;
      }

      layer.bindPopup(popupContent, {
        maxWidth: 250,
        minWidth: 100,
        className: `location-popup`,
      });
    };

    L.geoJSON(this._data, {
      style(feature) {
        return feature.properties && feature.properties.style;
      },
      onEachFeature,
      pointToLayer(feature, latlng) {
        return L.marker(latlng, {
          icon: L.divIcon({
            className: 'bi bi-geo-alt-fill',
            html: `<svg width="34" height="34" fill="#fc2a05" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
          </svg>`,
            iconSize: [34, 61],
          }),
        });
        // return L.circleMarker(latlng, {
        //   radius: 8,
        //   fillColor: '#ff7800',
        //   color: '#000',
        //   weight: 1,
        //   opacity: 1,
        //   fillOpacity: 0.8,
        // });
      },
    }).addTo(this._map);
  }

  clear() {
    this._map.remove();
  }

  get map() {
    return this._map;
  }

  set data(value) {
    this._data = value;
  }

  set addmarker(value) {
    this._addMarker = value;
  }
}

export default LeafletView;
