import { API_URL } from './config.js';
import { getJSON } from './helper.js';

import 'regenerator-runtime/runtime';

export const state = {
  dropDown: {
    data: {},
    state: {
      data: [],
      selected: 'select state',
    },
    city: {
      data: [],
      selected: 'select city',
    },
  },
};

const setDropdown = async function (values) {
  state.dropDown = {
    ...state.dropDown,
    data: values,
    state: {
      ...state.dropDown.state,
      data: Object.keys(values),
    },
    city: {
      ...state.dropDown.city,
    },
  };
  return true;
};

export const dropDownValues = async function () {
  try {
    const initialData = localStorage.getItem('_cs');
    if (initialData) {
      setDropdown(JSON.parse(initialData));
      return;
    }
    const { data } = await getJSON(`${API_URL}/statesCities`);
    // const result = Object.groupBy(data, ({ state_name }) => state_name);
    localStorage.setItem('_cs', JSON.stringify(data));
    // const initData = localStorage.getItem('_cs');
    setDropdown(data);
    return;
  } catch (error) {
    throw error;
  }
};

export const setDropdownValue = async function (value, type) {
  try {
    const dataObj = state.dropDown.data;
    const cityObj =
      type === 'city'
        ? state.dropDown.city.data.filter(item => item.name === value)[0]
        : state.dropDown.city.data.obj;

    return new Promise(resolve => {
      state.dropDown = {
        ...state.dropDown,
        state: {
          ...state.dropDown.state,
          selected: type === 'state' ? value : state.dropDown.state.selected,
        },
        city: {
          ...state.dropDown.city,
          data: type === 'city' ? state.dropDown.city.data : dataObj[value],
          selected: type === 'city' ? value : 'select city',
          obj: cityObj,
        },
      };
      resolve(state);
    });
  } catch (error) {
    throw error;
  }
};

export const setPreference = async function (data) {
  try {
    state.dropDown.state.selected = data.state.selected;
    state.dropDown.city.selected = data.city.selected;
    (state.dropDown.city.data = state.dropDown.data[data.state.selected]),
      (state.dropDown.city.obj = data.city.obj);
  } catch (error) {
    throw error;
  }
};

const init = function () {
  dropDownValues();
  document.getElementById('footer--year').textContent =
    new Date().getFullYear();
};

init();
