import { TIMEOUT_SEC } from './config.js';

const timeout = function (s) {
  return new Promise(function (_, reject) {
    setTimeout(function () {
      reject(new Error(`Request took too long! Timeout after ${s} second`));
    }, s * 1000);
  });
};

export const getJSON = async function (url, uploadData = '', token = '') {
  try {
    const res = await Promise.race([
      fetch(
        `${url}`,
        uploadData
          ? {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'x-auth-token': token,
              },
              body: JSON.stringify(uploadData),
            }
          : {
              headers: {
                'Content-Type': 'application/json',
                'x-auth-token': token,
              },
            }
      ),
      timeout(TIMEOUT_SEC),
    ]);
    const data = await res.json();

    if (!res.ok) throw new Error(data.message);

    return data;
  } catch (error) {
    throw error;
  }
};

export const bodyJSON = async function (url, uploadData = '', token = '') {
  try {
    const res = await Promise.race([
      fetch(
        `${url}`,
        uploadData
          ? {
              method: 'POST',
              headers: {
                // 'Content-Type': 'multipart/form-data',
                'x-auth-token': token,
              },
              body: uploadData,
            }
          : {
              headers: {
                'Content-Type': 'application/json',
                'x-auth-token': token,
              },
            }
      ),
      timeout(TIMEOUT_SEC),
    ]);
    const data = await res.json();

    if (!res.ok) throw new Error(data.message);

    return data;
  } catch (error) {
    throw error;
  }
};
